import React from "react";

function Message(isVisible) {
  return (
    <>
      <span className={isVisible}>
        <pre>{`
Първа награда - не се присъжда

Втора награда си поделят:

•	Петрана Николаева Петрова от Враца

СВОБОДНО  СТИХОТВОРЕНИЕ

В далечните откъслечни минути
изгаря всеки спомен като факла.
Сърцето е свободно да се пукне.
Животът не робува на понятия.

Отлиташ и се връщаш сам към себе си.
Сънуваш свое собствено разпятие.
Броиш лъжи, разкъсващи до белези,
а в дланите си стискаш топъл вятър.

Защо си? Докъде си? И за колко?
Оглеждаш се в очите на небето.
Така е поносима всяка болка,
макар да ражда нови битието.

Намери ли изгубените истини
(та в тяхна чест съсипа доста мелници)?!
Мечтите ти, разбити и улисани,
са къшей хляб за гладните ти делници.

И ако всеки порив е ръждясал,
поддал се на великата измама,
че си длъжник единствено на вятъра,
ти имаш свое право да те няма!

•	Ненчо Илиев Добрев   от  гр. Ямбол

ИЗМИСЛЯМ

Измислям си деня – узрял от много влюбване,
с черна шапка, тънка риза, вратовръзка.
Накъде ме води – сигурно до следващо загубване,
додето се стопя в ръката му безгрижно дръзка.

Нощта измислям – пълна с жилави поверия,
със сенки на изминали раздели и приятелства.
Под булото й тъмно може да намеря
останал златен прах – за доказателство.

Измислям пътя – дълъг, причудлив, несвършващ,
с пропадане, с издигане и още куп неравности
и аз препускам храбро като буен кон развързан,
подмамен ту от топъл вятър, ту от сенките на храстите.

Измислям светлината – мощна, зрима, необятна -
просмукан от елея й аз светя сам.
И няма точни думи, няма правилни понятия,
които да разтурят този светещ храм.

И все вървя умислен и търся някаква човечност,
но тя се крие плахо в дупката на кърт.
Затуй измислям цялата налична вечност -
какъв живот ще е, ако ме чака смърт.


Трета награда се дава на трима поети:

•	Анелия Янковска-Сенгалевич  от София

АКТУАЛНО

И каква ли е тази любов листопадна?
Ураганите брулят света. Бомби падат.
Ято дронове вместо небе. Самолети!
Братът брата погубва със залп от ракети.

Пò на юг, през морета – разруха и гибел.
Остаряват децата тук, ако са живи.
И заплаква дъждът вместо нас. Състрадава!
Рукват кални потоци, растат и ни давят.

Аз до твоето рамо сега ще се сгуша.
Ти бъди  вярна скала – спасителна суша!
Щом светът е обречен на скръб,  на забрава,
днес да го топлим с любов. Това ни остава


•	Ивелина Димитрова Радионова  от гр. Провадия

СОЛ

Устата ми залепна да мълча. И онемях
подобно риба, хваната от някой много, много тъжен.
И станах сол, от всички сини океани прах,
и планина от горест мога да затрупам. Ала изведнъж
незнайно как солта се разтопи от дума, от сълза, от вик
и се обърка главоломно земното летоброене,
а аз от сол достатъчна, за да потъне кораб, станах миг,
единствен миг, а всъщност - цяла вечност, от любов стаена.
Не помня нищо друго. Помня само, че мигът се счупи.
Отново станах сол над залък хляб, досущ като на Тайната вечеря,
а после вино бях, с Христова кръв до капчица изкупено,
да ме преглъщат жадно юди и томаневерници.
Забравих, че ме има на света, дали защото някому не е било потребно
да бъда риба няма колкото солта и точно толкова да побелея.
Навярно трябвало е да платя със люспи тишина на бедните
и на богатите най-старата вина, та светлина да я огрее.
В устата ми потече светлина, по-хубава от радост непресторена
на някой много, много тъжен от рождение…
Потече и стопи у мен солта. Сега се уча да говоря
и на ония, рибешки мълчащите, да давам думички спасение.

•	Иван Енчев Иванов - от Хасково

ПРЕДЕЛ

Тук даряват ми криле
най-насрещните копнежи.
Всеки иска ме орле
само с негови летежи.
През съдбовния предел
между Севера и Юга
аз отдавна съм поел
път към бъдеща сполука.
Севернякът в мен е бунт,
а южнякът – все утеха.
Сам изглеждам най-неук
в кръстословица нелека.

Сея радости и скръб
в двуизмерена насока,
както желъди на дъб
в посев на бразда дълбока.
Съвестта ми – ревизор –
все добро и зло пресмята.
Чопли слава и позор
в равносметка пребогата…
Ето участта ми днес:
стара мечка с люта рана
броди в неизброден лес,
жива драна-недодрана.

Журито присъжда  и  четири  специални отличия на:


•	Върбан Николов Велчев от Враца

В НОЩТА СРЕЩУ СЕЛСКИЯ СБОР

Сънувах, че съм пак на село
във двора с къщицата стара,
варосана за Сбора в бело
и свела стряха до хамбара.

Оназ, що сринаха отдавна
баща ми, чичо ми и свако,
за да си поделят поравно
греди, дъски и камънака.

Позната бе ни всяка зирка
във нейната снага разбита,
където лете щурци свиркат
и лазят мравки под вратите.

Кога се качехме на нея,
Небето слизаше тъй близко.
И вятърът дори не смееше
да духне в белите ни ризки,

че току-виж сме полетели
като глухарчета над двора ...
Затуй в съня си толкоз смело
кръжехме ангелски в простора ...

Макар че имаше ключалка,
заключена не си я спомням.
Във хорските очи бе малка,
но в моето сърце - огромна.



•	Здравка Петрова Христова от гр. Русе

 ГУБЕР

                 „Уж да видя Америка и внуците, а то…
                   По цял ден – сама в апартамента им
                 		   на не знам кой етаж….“
                    (случайно дочути думи на една българка)

Лукс но в клетка. Бетонна. Заглъхнал впил поглед прозрачен
в канапето отсреща присяда блед призрак на хлад.
Тя пропада в безмълвие. Даже капчукът не плаче.
Даже въздухът-смог е с привкус на полуфабрикат

Само споменът плахо се вмъква – бездомниче- коте
и се сгушва нечуто до нея на пухче клъбце
и открехва в зида на мъглата невидима протка,
жичка - път към изгубен свят свиден за нея  преде....
..........................................................
Жички-родови корени опва в основа. В съня си...
Плисва вътък- небе, разлюляно, та пламнало в жар!
И надвили чукарите тъмни дъбрави-талази....
Грохот... ромон... предания-сенки в нощта. Рев на звяр!

Вплита топлата майчина ласка на черната угар...
Ръсва злак-резеда. Тук-там грейват очички-цветя...
Дъх на здравец... роса... Ех, да може с вълшебния губер
да пропъстри и сгрее незнайната чужда земя!

Остър звук грубо скълцва и скупчва ефирните жици
на съня-стан... Не! Никой! На чужд, непонятен език
грачат в сиво безвремие мрачните рошави птици
чепкат глухо нагърчен до болка в душата й вик.....


•	Лъчезар Петров Селяшки от с. Сатовча, Благоевградско

МЪЛЧАНИЕ

Могила, посивял параклис,
трева и камъни безброй.
Тук никой никого не чака
под ярко слънце – пчелен рой.

Мълчат дървета, сякаш стражи,
в тревата – сънено щурче –
Дъга описва – зов протяжен –
сокол и слънчев лъч влече.

Мълча. Не питам се отново
познах ли свойта същина.
Погива свят, остава словото
след чезнещите имена.


•	Пламен Анакиев от София

ПОДИР ДУХА НА ИВАН МЕТОДИЕВ

Умувам мълчешком,
полегнал
върху рехава тревица:

Ще се ожени ли
щурецът
за пчелната царица...

А дали
ще има дом,
или – стени под наем...

Съдбата му
не зная.
Тя остава тайна.

Но в стиховете вярвам.
Ще бъде онова,
което е случайно.

Благодарим на всички участници.
Пожелаваме им здраве, творчески успехи
и прекрасни Коледни и Новогодишни празници!


                `}</pre>
      </span>
    </>
  );
}

export default Message;
